var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template-create-wizard-step template-create-wizard__wrapper"},[_c('div',{staticClass:"template-create-wizard-wrapper-content"},[_c('h1',{staticClass:"font-weight-regular my-5"},[_vm._v(" Job Template ")]),_c('h3',{staticClass:"font-weight-regular mb-3"},[_vm._v(" Select the Job Templates that will be available in your work order. ")]),_c('v-form',{ref:"form",staticClass:"template-create-wizard-add-field mt-10 d-flex justify-start",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex"},[_c('v-autocomplete',{staticClass:"template-create-wizard-job-template-field mb-3",attrs:{"id":"template-create__select-templates","name":"job-template","label":_vm.jobTemplateField.label,"items":_vm.jobTemplateItems,"return-object":"","item-text":"jobTemplateName","item-value":"jobTemplateId","required":"","rules":[
              _vm.jobTemplateField.rules.noDuplicates
            ]},on:{"change":_vm.onChangeJobTemplate,"keydown":_vm.onChangeJobTemplate},model:{value:(_vm.jobTemplateSelection),callback:function ($$v) {_vm.jobTemplateSelection=$$v},expression:"jobTemplateSelection"}})],1),_c('v-btn',{staticClass:"mt-3 ml-10",attrs:{"id":"template-create__add-template","color":"primary","disabled":!_vm.validateJobTemplateSelection},on:{"click":_vm.addJobTemplate}},[_vm._v(" Add ")])],1)]),_c('div',[_c('v-data-table',{staticClass:"\n        fill-height\n        mt-2\n        flex-column\n        justify-space-between\n        template-create-wizard-added-table\n      ",attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.addedTemplates,"hide-default-footer":"","disable-sort":"","disable-pagination":""},scopedSlots:_vm._u([{key:"header.actions",fn:function(ref){
            var header = ref.header;
return [_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(header.text))])]}},{key:"header.default",fn:function(ref){
            var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"slot":"activator","small":""},slot:"activator"},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v("This will be the Job Template created when you validate your Work Order.")])])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-around justify-lg-start float-right"},[_c('v-btn',{attrs:{"id":"template-create__delete-template","icon":""},on:{"click":function($event){return _vm.deleteTemplate(item)}}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v(" mdi-delete ")])],1)],1)]}},{key:"item.isDefault",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex ml-2"},[_c('v-checkbox',{staticClass:"align-center",attrs:{"id":"template-create__select-template_isDefault","input-value":item.isDefault},on:{"click":function($event){return _vm.defaultTemplate(item)}}})],1)]}}],null,true)})],1)],1),_c('v-divider',{staticClass:"mb-3"}),_c('div',{staticClass:"template-create-wizard-wrapper-navigation d-flex justify-end"},[_c('v-btn',{staticClass:"template-create-wizard-back-btn align-self-end mr-4",attrs:{"id":"template-create__back-btn","color":"secondary"},on:{"click":_vm.navigateBack}},[_vm._v(" Back ")]),_c('v-btn',{staticClass:"template-create-wizard-next-btn align-self-end",attrs:{"id":"template-create__next-btn","color":"primary","disabled":!_vm.defaultTemp},on:{"click":_vm.navigateNext}},[_vm._v(" Next ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }