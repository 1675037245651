import { render, staticRenderFns } from "./TemplateCreateWizardStatusTransitionSelectionStep.vue?vue&type=template&id=2fe0727f&"
import script from "./TemplateCreateWizardStatusTransitionSelectionStep.vue?vue&type=script&lang=ts&"
export * from "./TemplateCreateWizardStatusTransitionSelectionStep.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCheckbox,VListItem,VListItemContent,VListItemTitle,VSwitch})
