






































































import { defineComponent, ref, computed } from '@vue/composition-api';
import Store from '@/store';
import { IWorkOrderTemplateStatuses } from '@/types';

export default defineComponent({
  props: {
    statusName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const dialog = ref(false);
    const form = ref();
    const maxNameLength = 100;
    const newStatusName = ref('');
    const statuses = computed(
      ():
      (IWorkOrderTemplateStatuses) [] => Store.getters['workOrderTemplate/getData'].jobTemplateStatuses,
    );

    const getStatusName = () => {
      newStatusName.value = props.statusName;
    };

    const statusNameField = {
      label: 'Status Name',
      counter: maxNameLength,
      rules: {
        /**
         * Checks if value is lesser or equal to 100 characters
         * @param {string} value Value to be checked
         * @return {boolean | string} True and string if lesser or equal
         */
        counter: (value: string): boolean | string => {
          if (!value) return true;
          return value.length <= maxNameLength || 'Max 100 characters';
        },

        noDuplicates: (value: string) => {
          // it can be the same status name the user clicked on
          if (props.statusName === value) return true;
          if (value) {
            const itemIndex = statuses.value.findIndex((item) => 'statusName' in item && item.name.toLowerCase() === value.toLowerCase());
            return itemIndex >= 0 ? 'This status has already been added' : true;
          }
          return true;
        },
      },
    };

    const closeDialog = () => {
      dialog.value = false;
      form.value.reset();
    };

    /**
     * Validate form and close the dialog.
     */
    const validateForm = () => form.value.validate();

    const editStatusName = () => {
      // todo consult with M
      const updateIndex = statuses.value.findIndex((stat) => stat.name === props.statusName);
      statuses.value[updateIndex].name = newStatusName.value;

      Store.dispatch('workOrderTemplate/setStatuses', statuses.value);
      closeDialog();
    };

    /**
     * Checks if template name field is valid
     * @return {boolean} True if valid
     */
    const validateStatusName = computed((): boolean => {
      const noDuplicates = statusNameField.rules.noDuplicates(newStatusName.value);
      const counter = statusNameField.rules.counter(newStatusName.value);

      if (!newStatusName.value) return false;

      return (
        typeof noDuplicates === 'boolean'
          && noDuplicates
          && typeof counter === 'boolean'
          && counter
      );
    });

    return {
      maxNameLength,
      editStatusName,
      closeDialog,
      newStatusName,
      statusNameField,
      dialog,
      validateForm,
      form,
      validateStatusName,
      getStatusName,
    };
  },
});
