

































































import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  setup() {
    const dialog = ref(false);
    const form = ref();

    /**
     * Closes dialog box
     */
    const closeDialog = () => {
      dialog.value = false;
      form.value.reset();
    };

    const addTrigger = () => {

    };

    return {
      form,
      dialog,
      closeDialog,
      addTrigger,
    };
  },
});
