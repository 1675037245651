











































import {
  defineComponent, computed, Ref,
} from '@vue/composition-api';
import Store from '@/store';
import { IVComboboxItem } from '@/types';

export default defineComponent({
  emit: ['navigateNext', 'navigateBack'],

  setup(_props, { emit }) {
    const manuallyCreateField = {
      label: 'Manually Create Work Orders',
      items: (): IVComboboxItem[] => [{
        text: 'Yes',
        value: true,
      }, {
        text: 'No',
        value: false,
      }],
    };

    const selectedManualCreateValue = computed((): IVComboboxItem | undefined => manuallyCreateField.items().find((item) => item.value === Store.getters['workOrderTemplate/getManualCreateValue']));

    const selectManualCreateValue = (status: Ref<IVComboboxItem> | undefined) => {
      const value = status ? status.value : '';
      Store.dispatch('workOrderTemplate/setManualCreateValue', value);
    };

    /**
     * Checks if Manually create work orders name field is valid
     * @return {boolean} True if valid
     */
    const validateManualCreate = computed((): boolean => {
      const val = Store.getters['workOrderTemplate/getManualCreateValue'];
      return typeof val === 'boolean';
    });

    /**
     * Emits an event warning that user wants to navigate to the previous page
     * of the wizard and passes the current step as param
     * Clears the table and the combobox fields
     */
    const navigateBack = () => {
      emit('navigateBack', 'manualCreate');
    };

    /**
     * Emits an event warning that user wants to navigate to the next page
     * of the wizard and passes the current step as param
     */
    const navigateNext = () => {
      emit('navigateNext', 'manualCreate');
    };

    return {
      selectedManualCreateValue,
      selectManualCreateValue,
      validateManualCreate,
      manuallyCreateField,
      navigateBack,
      navigateNext,
    };
  },
});
