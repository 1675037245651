












































import {
  defineComponent, computed, Ref,
} from '@vue/composition-api';
import Store from '@/store';
import { IVComboboxItem, IWorkOrderTemplateStatuses } from '@/types';

export default defineComponent({
  emit: ['navigateNext', 'navigateBack'],

  setup(_props, { emit }) {
    const selectedDefaultStatus = computed((): string => Store.getters['workOrderTemplate/getDefaultTemplateCreateStatus']);

    const selectDefaultStatus = (status: Ref<IVComboboxItem> | undefined) => {
      const value = status && status.value ? status.value : '';
      Store.dispatch('workOrderTemplate/setDefaultTemplateCreateStatus', value);
    };

    const jobStatusField = {
      label: 'Job Status',
      items: (): IVComboboxItem[] => {
        const items = Store.getters['workOrderTemplate/getData'].jobTemplateStatuses;
        return items.map((item: IWorkOrderTemplateStatuses) => ({
          text: item.name,
          value: item.name,
        }));
      },
    };

    /**
     * Checks if Status name field is valid
     * @return {boolean} True if valid
     */
    const validateJobStatus = computed((): boolean => {
      const jobTemplateValue = Store.getters['workOrderTemplate/getDefaultTemplateCreateStatus'];
      if (!jobTemplateValue) return false;
      return true;
    });

    /**
     * Emits an event warning that user wants to navigate to the next page
     * of the wizard and passes the current step as param
     */
    const navigateNext = () => {
      emit('navigateNext', 'statusName');
    };

    /**
     * Emits an event warning that user wants to navigate to the previous page
     * of the wizard and passes the current step as param
     */
    const navigateBack = () => {
      emit('navigateBack', 'statusName');
    };

    return {
      navigateNext,
      navigateBack,
      selectDefaultStatus,
      selectedDefaultStatus,
      validateJobStatus,
      jobStatusField,
    };
  },
});
