
























































































































































































import {
  defineComponent, ref, watch, computed,
} from '@vue/composition-api';
import TemplateCreateWizardStatusStepEditDialog from '@/components/TemplateCreateWizard/TemplateCreateWizardStatusStepEditDialog.vue';
import Store from '@/store';
import { IWorkOrderTemplateStatuses } from '@/types';

export default defineComponent({
  emit: ['navigateNext', 'navigateBack'],
  components: {
    TemplateCreateWizardStatusStepEditDialog,
  },
  props: {
    tempId: {
      type: String,
      default: null,
    },
    isPublished: {
      type: Boolean,
      default: false,
    },
  },
  setup(_props, { emit }) {
    const minNameLength = 3;
    const maxNameLength = 100;
    const statusName = ref('');
    const isStatusAdded = ref(false);
    const isMaxStatusesAdded = ref(false);
    let itemId = 0;
    const headers = ref([
      {
        text: 'Status Name',
        align: 'start',
        sortable: false,
        value: 'statusName',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
      }]);

    const statuses = computed(
      ():
      (IWorkOrderTemplateStatuses) [] => Store.getters['workOrderTemplate/getData'].jobTemplateStatuses,
    );

    const isLoading = computed(() => Store.getters['workOrderTemplate/getLoading']);

    const firstStatuses = computed(() => statuses.value.slice(0, 8));
    const lastStatuses = computed(() => statuses.value.slice(8, 15));

    /**
     * Function is used to push statuses to the templates statuses
     */
    const onAddStatus = () => {
      const statusObject: IWorkOrderTemplateStatuses = {
        id: itemId += 1,
        order: -1,
        name: statusName.value,
        statusInbound: {
          statusInboundName: '',
        },
        fieldChange: [],
        jobStatusChange: {
          geopalStatusId: 0,
          geopalStatusName: '',
        },
        transitions: [],
      };
      statuses.value.push(statusObject);
      statusName.value = '';
      Store.dispatch('workOrderTemplate/setStatuses', statuses.value);
    };

    const onDeleteStatus = (item: IWorkOrderTemplateStatuses) => {
      Store.dispatch('workOrderTemplate/deleteStatuses', item);
    };

    const statusNameField = {
      label: 'Status Name',
      counter: maxNameLength,
      rules: {
        /**
         * Checks if value is lesser or equal to 100 characters
         * @param {string} value Value to be checked
         * @return {boolean | string} True and string if lesser or equal
         */
        counter: (value: string): boolean | string => {
          if (!value) return true;
          if (value.length < minNameLength) return 'Min 3 characters';
          return (value.length <= maxNameLength) || 'Max 100 characters';
        },

        noDuplicates: (value: string) => {
          if (value) {
            const itemIndex = statuses.value.findIndex((item) => 'name' in item && item.name.toLowerCase() === value.toLowerCase());
            return itemIndex >= 0 ? 'This status has already been added' : true;
          }
          return true;
        },

        limit: () => {
          if (statuses.value.length > 14) return 'Max amount of statuses is 15.';
          return true;
        },
      },
    };

    /**
     * Status Name Form Validation
     * Check for duplication of status name & field value exist
     */
    const validateStatusName = computed((): boolean => {
      const noDuplicates = statusNameField.rules.noDuplicates(
        statusName.value,
      );
      if (typeof noDuplicates === 'string' || !statusName.value) return false;

      const counter = statusNameField.rules.counter(
        statusName.value,
      );
      if (typeof counter === 'string') return false;

      return true;
    });

    /**
     * Check that at least one status is there before allowing the user to proceed
     * Check that only 15 statuses are allowed to be added
     */
    watch(statuses, () => {
      isMaxStatusesAdded.value = statuses.value.length > 14;
      isStatusAdded.value = statuses.value && statuses.value.length > 0;
    });

    /**
     * Emits an event warning that user wants to navigate to the previous page
     * of the wizard and passes the current step as param
     * Clears the table and the combobox fields
     */
    const navigateBack = () => {
      emit('navigateBack', 'status');
    };

    /**
     * Emits an event warning that user wants to navigate to the next page
     * of the wizard and passes the current step as param
     */
    const navigateNext = () => {
      emit('navigateNext', 'status');
    };
    return {
      statusName,
      onAddStatus,
      statuses,
      firstStatuses,
      lastStatuses,
      headers,
      isStatusAdded,
      isMaxStatusesAdded,
      navigateNext,
      navigateBack,
      statusNameField,
      validateStatusName,
      onDeleteStatus,
      isLoading,
    };
  },
});
