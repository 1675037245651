import { render, staticRenderFns } from "./TemplateCreateWizardSelectTemplates.vue?vue&type=template&id=79853c64&scoped=true&"
import script from "./TemplateCreateWizardSelectTemplates.vue?vue&type=script&lang=ts&"
export * from "./TemplateCreateWizardSelectTemplates.vue?vue&type=script&lang=ts&"
import style0 from "./TemplateCreateWizardSelectTemplates.vue?vue&type=style&index=0&id=79853c64&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79853c64",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAutocomplete,VBtn,VCheckbox,VDataTable,VDivider,VForm,VIcon,VTooltip})
