





































































































































import {
  defineComponent, computed, ref,
} from '@vue/composition-api';
import Store from '@/store';
import TemplateCreateWizardStatusTransitionSelectionStep from '@/components/TemplateCreateWizard/TemplateCreateWizardStatusTransitionSelectionStep.vue';
import { IWorkOrderTemplateStatuses } from '@/types';

export default defineComponent({
  emit: ['navigateNext', 'navigateBack'],
  components: {
    TemplateCreateWizardStatusTransitionSelectionStep,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(_props, { emit }) {
    const selectedNavItemDisplay = ref();
    const statuses = computed(
      ():
      (IWorkOrderTemplateStatuses) [] => Store.getters['workOrderTemplate/getData'].jobTemplateStatuses,
    );

    const selectedNavItem = computed(() => Store.getters['workOrderTemplate/getSelectedNavItem']);

    const selectedTransitionsData = computed(
      ():
      (IWorkOrderTemplateStatuses) [] => Store.getters['workOrderTemplate/transitionStatuses'](
        selectedNavItem.value,
      ),
    );

    const selectedTranItems = ref();

    const navStatusData = computed(
      ():
      (IWorkOrderTemplateStatuses) [] => Store.getters['workOrderTemplate/getData'].jobTemplateStatuses,
    );

    const selectStatus = (itemId: number) => {
      Store.dispatch('workOrderTemplate/setSelectedNavItem', itemId);
    };

    /**
     * Emits an event warning that user wants to navigate to the previous page
     * of the wizard and passes the current step as param
     * Clears the table and the combobox fields
     */
    const navigateBack = () => {
      selectStatus(-1);
      selectedNavItemDisplay.value = null;
      emit('navigateBack', 'transition');
    };

    /**
     * Emits an event warning that user wants to navigate to the next page
     * of the wizard and passes the current step as param
     */
    const navigateNext = () => {
      /* reset selected item */
      selectedNavItemDisplay.value = null;
      Store.dispatch('workOrderTemplate/setSelectedNavItem', -1);

      emit('navigateNext', 'transition');
    };

    const enableNextButton = () => {
      const incompleteStatuses = statuses.value.filter(
        (status) => status.transitions.length === 0,
      );
      return !!incompleteStatuses.length;
    };

    return {
      statuses,
      selectedNavItem,
      selectedTranItems,
      selectedNavItemDisplay,
      navigateBack,
      navigateNext,
      selectStatus,
      enableNextButton,
      selectedTransitionsData,
      navStatusData,
    };
  },
});
