









































import { defineComponent, ref, computed } from '@vue/composition-api';
import Store from '@/store';
import { delayCall } from '../../utils';

export default defineComponent({
  emit: ['navigateNext'],

  setup(_props, { emit }) {
    const templateName = ref(
      Store.getters['workOrderTemplate/getData'].name as string,
    );

    const isLoading = computed(
      () => Store.getters['workOrderTemplate/getLoading'] as boolean,
    );

    const errorMessages = ref('');

    const templateNameField = {
      label: 'Template Name',
      hint: 'Choose wisely this can\'t be changed in the future.',
      counter: 100,
      rules: {
        /**
           * Checks if value is not falsy
           * @param {string} value Value to be checked
           * @return {boolean | string} True if valid or a text if false
           */
        required: (value: string): boolean | string => !!value || 'This field is required',

        /**
         * Checks if value is lesser or equal to 100 characters
         * @param {string} value Value to be checked
         * @return {boolean | string} True or string if lesser or equal
         */
        counter: (value: string): boolean | string => {
          if (!value) return false;
          return value.length <= 100 || 'Max 100 characters';
        },
      },
    };

    /**
       * Checks if template name field is valid
       * @return {boolean} True if valid
       */
    const validateTemplateName = computed((): boolean => {
      const required = templateNameField.rules.required(templateName.value);
      const counter = templateNameField.rules.counter(templateName.value);
      const isAvailable = Store.getters['workOrderTemplate/getValidation'].name;

      const setErrorMessages = (value: string) => {
        errorMessages.value = value;
      };

      /**
         * Because name availability is an async call it can't be part of the fields
         * rules, so this is an workaround using error-messages prop
         */
      if (typeof isAvailable === 'string') setErrorMessages(isAvailable);
      else setErrorMessages('');

      return (
        !isLoading.value
          && typeof required === 'boolean'
          && required
          && typeof counter === 'boolean'
          && counter
          && typeof isAvailable === 'boolean'
          && isAvailable
      );
    });

    /**
       * On template name field input updated name in store
       * @param {string} value Field value
       */
    const onInputName = (value: string) => {
      // if counter rule fails means that it exceeded the number of
      // characters allowed, no need to validate availability
      if (templateNameField.rules.counter(value) !== true) return;

      delayCall(() => {
        Store.dispatch('workOrderTemplate/setName', value);
        Store.dispatch('workOrderTemplate/validateName');
      }, 750);
    };

    /**
       * Emits an event warning that user wants to navigate to the next page
       * of the wizard and passes the current step as param
       */
    const navigateNext = () => {
      emit('navigateNext', 'templateName');
    };

    return {
      templateName,
      templateNameField,
      validateTemplateName,
      errorMessages,
      isLoading,
      onInputName,
      navigateNext,
    };
  },
});
