
















































































































































import {
  defineComponent, Ref, ref, watch, onMounted, computed,
} from '@vue/composition-api';
import router from '@/router';
import Store from '@/store';
import {
  IWorkorderTemplateData,
} from '@/types';
import TemplateCreateWizardCloseDialog from './TemplateCreateWizardCloseDialog.vue';
import TemplateCreateWizardStatusStep from './TemplateCreateWizardStatusStep.vue';
import TemplateCreateWizardNameStep from './TemplateCreateWizardNameStep.vue';
import TemplateCreateWizardSummaryStep from './TemplateCreateWizardSummaryStep.vue';
import TemplateCreateWizardStatusTransitionStep from './TemplateCreateWizardStatusTransitionStep.vue';
import TemplateCreateWizardAutomatedStatusTransitionStep from './TemplateCreateWizardAutomatedStatusTransitionStep.vue';
import TemplateCreateWizardAllowManualCreate from './TemplateCreateWizardAllowManualCreate.vue';
import TemplateCreateWizardSelectTemplates from './TemplateCreateWizardSelectTemplates.vue';
import TemplateCreateWizardSelectTemplatesDefaultStatus from './TemplateCreateWizardSelectTemplatesDefaultStatus.vue';

export default defineComponent({
  emit: ['templateCreated', 'templateSaved'],

  components: {
    TemplateCreateWizardCloseDialog,
    TemplateCreateWizardNameStep,
    TemplateCreateWizardStatusStep,
    TemplateCreateWizardSummaryStep,
    TemplateCreateWizardStatusTransitionStep,
    TemplateCreateWizardAutomatedStatusTransitionStep,
    TemplateCreateWizardSelectTemplates,
    TemplateCreateWizardSelectTemplatesDefaultStatus,
    TemplateCreateWizardAllowManualCreate,
  },

  setup(_props, { emit }) {
    onMounted(() => {
      Store.dispatch('jobTemplate/loadData');
    });

    Store.dispatch('workOrderTemplate/resetStoreState');
    const isPublished = computed(() => (Store.getters['templateCustomisation/getData'] ? Store.getters['templateCustomisation/getData'].published : false as boolean));
    const valid = false;
    const stepperList = ref();

    const dialog = ref(false);
    let templateId = '';
    if (router.app) { templateId = router.app.$route.params.templateId; }

    /**
     * Closes the wizard dialog
     */
    const closeDialog = () => {
      dialog.value = false;
      if (templateId) {
        /* clear data in the store */
        router.go(-1);
        Store.dispatch('workOrderTemplate/resetStoreState');
      }
    };

    const form = ref();

    /**
     * Step must be a number (or a number in a string) otherwise stepper
     * component will not make a back animation.
     *
     * Steps:
     * 1 - Template Name
     * 2 - Job Template
     * 3 - Summary
     */
    const step: Ref<string | number> = ref(1); // init step

    /*
      check if there is a tempId in the router
      if there is this means its an edit.
      therefore we set dialog to true.
    */
    if (templateId) {
      const templateData = ref(
        Store.getters['templateCustomisation/getData'] as IWorkorderTemplateData,
      );

      const loadTemplateStatuses = async () => {
        await Store.dispatch('workOrderTemplate/loadWorkOrderJobTemplateData', templateData.value);
      };
      loadTemplateStatuses();
      dialog.value = true;
      /* check if the template is published
       * if 'Yes' skip to review screen otherwise step '2' */
      if (isPublished.value) step.value = 8;
      else step.value = 2;
    }

    /**
     * Changes current step
     * @param {string} newStep New step to be displayed
     */
    const changeStep = (newStep: string | number) => {
      step.value = newStep;
    };

    // Watch if dialog has been closed, if true clears form; validation and store
    watch(dialog, (from, to) => {
      if (from && !to && form.value) {
        form.value.reset();
        form.value.resetValidation();
        Store.dispatch('workOrderTemplate/resetStoreState');
        changeStep(1);
      }
    });

    /**
       * On navigateNext event emitted we determine the next stepper step
       * based on the current step.
       * @param {string} fromStep Step that's triggering the event
       */
    const onNavigateNext = (fromStep: string) => {
      switch (fromStep) {
        case 'templateName':
          changeStep(2);
          break;
        case 'status':
          changeStep(3);
          break;
        case 'transition':
          changeStep(5);
          break;
        case 'automated':
          changeStep(5);
          break;
        case 'templateSelection':
          changeStep(6);
          break;
        case 'statusName':
          changeStep(7);
          break;
        case 'manualCreate':
          changeStep(8);
          break;
        default:
          changeStep(1);
          break;
      }
    };

    /**
       * On onNavigateBack event emitted we determine the previous stepper step
       * based on the current step.
       * @param {string} fromStep Step that's triggering the event
       */
    const onNavigateBack = (fromStep: string) => {
      switch (fromStep) {
        case 'summary':
          changeStep(7);
          break;
        case 'manualCreate':
          changeStep(6);
          break;
        case 'statusName':
          changeStep(5);
          break;
        case 'templateSelection':
          changeStep(3);
          break;
        case 'automated':
          changeStep(3);
          break;
        case 'transition':
          changeStep(2);
          break;
        case 'status':
          changeStep(1);
          break;

        default:
          changeStep(1);
          break;
      }
    };

    /**
       * On createTemplate triggered creates the new template, closes the dialog,
       * and emits templateCreated
       */
    const onCreateTemplate = async () => {
      await Store.dispatch('workOrderTemplate/createTemplate');

      dialog.value = false;
      emit('templateCreated');
    };
    /**
     *  @summary calls the edit template and the closes the dialog
     *  @author Rory Harford
     */
    const onSaveTemplate = async () => {
      await Store.dispatch('workOrderTemplate/editTemplate', templateId);

      dialog.value = false;
      emit('templateSaved');
    };

    return {
      valid,
      dialog,
      closeDialog,
      step,
      form,
      onNavigateNext,
      onNavigateBack,
      onCreateTemplate,
      onSaveTemplate,
      templateId,
      isPublished,
      stepperList,
    };
  },
});
