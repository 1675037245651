var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[_c('v-dialog',{attrs:{"persistent":"","width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var ondialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var ontooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"template-create__status-name-edit-btn","icon":""},on:{"click":function($event){return _vm.getStatusName()}}},'v-btn',attrs,false),Object.assign({}, ontooltip, ondialog)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Change Status Name? ")]),_c('v-card-text',[_vm._v(" Do you want to edit the status name? "),_c('v-text-field',{staticClass:"mt-5",attrs:{"id":"template-create__edit-status-name","label":_vm.statusNameField.label,"counter":_vm.maxNameLength,"rules":[
            _vm.statusNameField.rules.noDuplicates,
            _vm.statusNameField.rules.counter ]},model:{value:(_vm.newStatusName),callback:function ($$v) {_vm.newStatusName=$$v},expression:"newStatusName"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"id":"template-create__cancel-btn","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"id":"template-create__status-edit-btn","color":"primary","text":"","disabled":!_vm.validateStatusName},on:{"click":function($event){return _vm.editStatusName()}}},[_vm._v(" Edit ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }