import { render, staticRenderFns } from "./TemplateCreateWizardSummaryStep.vue?vue&type=template&id=63bebf4f&scoped=true&"
import script from "./TemplateCreateWizardSummaryStep.vue?vue&type=script&lang=ts&"
export * from "./TemplateCreateWizardSummaryStep.vue?vue&type=script&lang=ts&"
import style0 from "./TemplateCreateWizardSummaryStep.vue?vue&type=style&index=0&id=63bebf4f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63bebf4f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCard,VCheckbox,VDataTable,VDivider,VIcon,VTab,VTabItem,VTabs,VTabsItems})
