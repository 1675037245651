var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template-create-wizard-step template-create-wizard__wrapper"},[_c('div',{staticClass:"template-create-wizard-wrapper-content"},[_c('h2',{staticClass:"font-weight-regular mt-6"},[_vm._v(" Select the Statuses names for your Template. ")]),_c('h3',{staticClass:"font-weight-regular mb-3"},[_vm._v(" Please add the statuses in the correct order. ")]),_c('v-form',{ref:"form",staticClass:"template-create-wizard-add-field mt-10 d-flex justify-start",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"template-create-wizard-add-status",attrs:{"id":"template-create__status-name","required":"","counter":"100","label":_vm.statusNameField.label,"disabled":_vm.isLoading,"rules":[
              _vm.statusNameField.rules.noDuplicates,
              _vm.statusNameField.rules.counter,
              _vm.statusNameField.rules.limit
            ]},model:{value:(_vm.statusName),callback:function ($$v) {_vm.statusName=$$v},expression:"statusName"}})],1),_c('v-btn',{staticClass:"mt-3 ml-10",attrs:{"id":"template-create__status-name-add","color":"primary","disabled":_vm.isMaxStatusesAdded || !_vm.validateStatusName || _vm.isLoading},on:{"click":_vm.onAddStatus}},[_vm._v(" Add ")])],1)]),_c('div',{staticClass:"template-create-wizard-data-flex-tables"},[_c('v-data-table',{staticClass:"\n      template-create-wizard-data-table\n      fill-height\n      pt-5\n      d-flex\n      flex-column\n      justify-space-between\n      ",class:_vm.lastStatuses.length ?'template-create-wizard-split-data-table':'',attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.firstStatuses,"loading":_vm.isLoading,"hide-default-footer":"","disable-sort":"","disable-pagination":""},scopedSlots:_vm._u([{key:"header.actions",fn:function(ref){
            var header = ref.header;
return [_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(header.text))])]}},{key:"item.statusName",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [_c('tr',[_c('td',[_vm._v(_vm._s(index + 1)+". "+_vm._s(item.name))])])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex ml-10",class:_vm.lastStatuses.length ? 'template-create-wizard-action-buttons-split'
              :'template-create-wizard__action-buttons-no-split'},[_c('TemplateCreateWizardStatusStepEditDialog',{attrs:{"status-name":item.name}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"template-create__first-status-name-delete","icon":""},on:{"click":function($event){return _vm.onDeleteStatus(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)]}}],null,true)}),(_vm.lastStatuses.length)?[_c('v-data-table',{staticClass:"\n      fill-height\n      pt-5\n      d-flex\n      flex-column\n      justify-space-between\n      template-create-wizard-split-data-table\n      ",attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.lastStatuses,"hide-default-footer":"","disable-sort":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.statusName",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [_c('tr',[_c('td',[_vm._v(_vm._s(index + 9)+". "+_vm._s(item.name))])])]}},{key:"header.actions",fn:function(ref){
              var header = ref.header;
return [_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(header.text))])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"template-create-wizard-action-buttons-split d-flex"},[_c('TemplateCreateWizardStatusStepEditDialog',{attrs:{"status-name":item.name}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"template-create__last-status-name-delete","icon":""},on:{"click":function($event){return _vm.onDeleteStatus(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)]}}],null,true)})]:_vm._e()],2)],1),_c('v-divider',{staticClass:"mb-3"}),_c('div',{staticClass:"template-create-wizard-wrapper-navigation d-flex justify-end"},[(!_vm.tempId)?_c('v-btn',{staticClass:"template-create-wizard-back-btn align-self-end mr-4",attrs:{"id":"template-create__back-btn","color":"secondary"},on:{"click":_vm.navigateBack}},[_vm._v(" Back ")]):_vm._e(),_c('v-btn',{staticClass:"template-create-wizard-next-btn align-self-end",attrs:{"id":"template-create__next-btn","color":"primary","disabled":!_vm.isStatusAdded},on:{"click":_vm.navigateNext}},[_vm._v(" Next ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }