var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template-create-wizard-step template-create-wizard__wrapper"},[_c('div',{staticClass:"template-create-wizard-wrapper-content"},[(!_vm.readOnly)?_c('h2',{staticClass:"font-weight-regular mb-5"},[_vm._v(" Status Transitions ")]):_vm._e(),_c('v-container',{class:{'template-create-wizard-outer-grid fill-height': _vm.readOnly},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"md":"3","lg":"2","cols":"12"}},[_c('v-card',{staticClass:"status-list-card flex-grow-1",attrs:{"tile":""}},[_c('v-list',{attrs:{"dense":""}},[_c('div',{staticClass:"mappings-edit-outer-box"},[_c('div',{staticClass:"nav-panel-selection"},[_c('v-subheader',{staticClass:"nav-panel-header"},[_vm._v(" Status name ")]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.navStatusData),function(item){return _c('v-list-item',{key:item.id,attrs:{"id":"template-create__transition-select"}},[_c('v-list-item-content',{attrs:{"id":"template-create__transition-select-content"},on:{"click":function($event){return _vm.selectStatus(item)}}},[_c('div',{staticClass:"v-treeview-node-selection"},[_c('div',{staticClass:"v-treeview-node-statusName",class:{
                              'v-treeview-node-statusName-selected':
                                item.transitions.length,
                            }},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)])])],1)}),1)],1)])])],1)],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"md":"3","lg":"2","cols":"12"}},[_c('div',{staticClass:"flex-grow-1"},[_c('v-btn',{staticClass:"\n                template-create-wizard-status-transition-btn\n                mb-4\n              ",attrs:{"id":"template-create__inbound-recieved","color":"secondary","block":"","disabled":_vm.inboundRecived || _vm.readOnly,"outlined":_vm.inboundRecivedCard &&
                  (!_vm.selectedItem ||
                    !_vm.selectedItem.statusInbound.statusInboundName
                    ? true
                    : false)},on:{"click":_vm.selectInBoundRecieved}},[_vm._v(" Inbound Recieved ")]),(!_vm.hideIfEdit)?_c('v-btn',{staticClass:"template-create-wizard-status-transition-btn mb-4",attrs:{"id":"template-create__field-change","color":"secondary","block":"","disabled":_vm.fieldChange || _vm.readOnly,"outlined":_vm.fieldChangeCard},on:{"click":_vm.selectFieldChange}},[_vm._v(" Field Change ")]):_vm._e(),_c('v-btn',{staticClass:"template-create-wizard-status-transition-btn mb-4",attrs:{"id":"template-create__job-status-change","color":"secondary","block":"","disabled":_vm.jobStatusChange || _vm.readOnly,"outlined":_vm.jobStatusChangeCard &&
                  (!_vm.selectedItem ||
                    !_vm.selectedItem.jobStatusChange.geopalStatusName
                    ? true
                    : false)},on:{"click":_vm.selectJobStatusChange}},[_vm._v(" Job Status Change ")])],1)]),_c('v-col',{staticClass:"d-flex flex-column justify-center",attrs:{"md":"6","lg":"8","cols":"12"}},[_c('v-card',{class:_vm.hideIfEdit
                ? 'template-create-wizard-actions-card align-center mb-3'
                : 'template-create-wizard-actions-card flex-grow-1 mb-1',attrs:{"disabled":_vm.inboundRecivedCard &&
                (!_vm.selectedItem || !_vm.selectedItem.statusInbound.statusInboundName
                  ? true
                  : false) || _vm.readOnly}},[(_vm.selectedItem)?_c('v-card-title',{staticClass:"status-transition-card-title"},[_vm._v(" You can update the Work Order status to '"+_vm._s(_vm.selectedItem.name)+"' via inbound selection a custom code to be used. ")]):_c('v-card-title',{staticClass:"status-transition-card-title"},[_vm._v(" You can update the Work Order status via inbound selection a custom code to be used. ")]),_c('v-card-text',[_c('v-text-field',{staticClass:"font-weight-regular",attrs:{"id":"template-create__inbound-value","label":"New Status Inbound","counter":"100","hint":"This is the value to be inbounded to update to this status","maxlength":"100","value":_vm.selectedItem
                    ? _vm.selectedItem.statusInbound.statusInboundName
                    : ''},on:{"change":_vm.updateNewStatusInbound}})],1)],1),(!_vm.hideIfEdit)?_c('v-card',{staticClass:"flex-grow-2 mb-1",attrs:{"disabled":_vm.fieldChangeCard || _vm.readOnly}},[_c('div',{staticClass:"d-flex"},[_c('v-card-title',{staticClass:"status-transition-card-title"},[_vm._v(" Define when a field value change should trigger the status transition. ")]),_c('div',{staticClass:"status-transition-card-btn"},[_c('v-btn-toggle',{attrs:{"id":"template-create__select-option-toggle","mandatory":true,"tile":"","color":"primary","group":""},model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"id":"template-create__select-option-and","value":"And"}},[_vm._v(" And ")]),_c('v-btn',{staticClass:"mt-3",attrs:{"id":"template-create__select-option-or","value":"Or"}},[_vm._v(" Or ")]),_c('TemplateCreateWizardAutomatedStatusTransitionEditStep',{staticClass:"mt-4"})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"\n                  mapping-step-data-table\n                  fill-height\n                  pt-5\n                  d-flex\n                  flex-column\n                  justify-space-between\n                ",attrs:{"fixed-header":"","hide-default-footer":"","disable-sort":"","disable-pagination":""}})],1)],1):_vm._e(),_c('v-card',{class:_vm.hideIfEdit
                ? 'template-create-wizard-actions-card mb-3 pb-1'
                : 'template-create-wizard-actions-card flex-grow-1 mb-1 pb-1',attrs:{"disabled":_vm.jobStatusChangeCard &&
                (!_vm.selectedItem || !_vm.selectedItem.jobStatusChange.geopalStatusName
                  ? true
                  : false) || _vm.readOnly}},[(_vm.selectedItem)?_c('v-card-title',{staticClass:"status-transition-card-title"},[_vm._v(" Choose the Job status that will transition the Work Order into '"+_vm._s(_vm.selectedItem.name)+"' status ")]):_c('v-card-title',{staticClass:"status-transition-card-title"},[_vm._v(" Choose the Job status that will transition the Work Order into another status ")]),_c('v-card-text',[_c('v-autocomplete',{staticClass:"font-weight-regular",attrs:{"id":"template-create__geopal-status","items":_vm.geopalStatuses,"item-text":"geopalStatusName","return-object":"","value":_vm.selectedItem
                    ? _vm.selectedItem.jobStatusChange.geopalStatusName
                    : '',"label":"GeoPal Job Status"},on:{"change":_vm.updateJobStatusChange}})],1)],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"mb-3"}),(!_vm.readOnly)?_c('div',{staticClass:"template-create-wizard-wrapper-navigation d-flex justify-end"},[_c('v-btn',{staticClass:"template-create-wizard-back-btn align-self-end mr-4",attrs:{"id":"template-create__back-btn","color":"secondary"},on:{"click":_vm.navigateBack}},[_vm._v(" Back ")]),_c('v-btn',{staticClass:"template-create-wizard-next-btn align-self-end",attrs:{"id":"template-create__next-btn","color":"primary"},on:{"click":_vm.navigateNext}},[_vm._v(" Next ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }