






























import {
  defineComponent, computed, ref,
} from '@vue/composition-api';
import Store from '@/store';
import { IWorkOrderTemplateStatuses } from '@/types';

export default defineComponent({
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    transitionStatusId: {
      type: Number,
      required: true,
    },
    navTransitionStatusId: {
      type: Number,
      required: true,
    },
    transitionStatusName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const selectNone = ref(false);
    const selectTran = computed(() => Store.getters['workOrderTemplate/getSelectTran'](
      props.navTransitionStatusId,
      props.transitionStatusId,
    ));

    const selectDisplay = computed(() => Store.getters['workOrderTemplate/getTranDisplayBtn'](
      props.navTransitionStatusId,
      props.transitionStatusId,
    ));

    const statuses = computed(
      ():
      (IWorkOrderTemplateStatuses) [] => Store.getters['workOrderTemplate/getData'].jobTemplateStatuses,
    );

    /**
     * Called when a user selects a checkbox to indicate a status is a transition status
     * it checks if it is the 'None' transition eliminating all other transition statuses
     * otherwise it adds or removes the status accordingly
     */
    const selectTransition = () => {
      const foundStatus = statuses.value.find(
        (status) => status.id === props.navTransitionStatusId,
      );
      if (!foundStatus) return;
      let { transitions } = foundStatus;

      if (props.transitionStatusId === -1) {
        const statusChecker = transitions.findIndex(
          (status) => status.id === props.transitionStatusId,
        );
        if (statusChecker === -1) {
          transitions = [{
            id: -1,
            name: 'None',
            display: false,
          }];
          selectNone.value = !selectNone;
        } else {
          /* remove item if they are un-clicking */
          transitions.splice(statusChecker, 1);
        }
      } else {
        const noneIndex = transitions.findIndex((status) => status.id === -1);
        if (noneIndex !== -1) transitions.splice(noneIndex, 1);

        const transitionItem = statuses.value.find(
          (status) => status.id === props.transitionStatusId,
        );
        if (!transitionItem) return;

        const statusChecker = transitions.findIndex(
          (status) => status.id === props.transitionStatusId,
        );
        /* checking if user already added item (i.e -1 item wasn't added) */
        if (statusChecker === -1) {
          transitions.push({
            id: transitionItem.id,
            name: transitionItem.name,
            display: false,
          });
        } else {
          /* remove item if they are un-clicking */
          transitions.splice(statusChecker, 1);
        }
      }

      foundStatus.transitions = transitions;
    };

    /**
     * Called when a user selects the display button for a status that is already
     * selected to be a transition status
     */
    const displayButtonTransition = () => {
      const navTransitionStatus = statuses.value.find(
        (status) => status.id === props.navTransitionStatusId,
      );
      if (!navTransitionStatus) return;

      const { transitions } = navTransitionStatus;
      const transitionItem = transitions.find(
        (status) => status.id === props.transitionStatusId,
      );

      if (!transitionItem) return;

      transitionItem.display = !selectDisplay.value;
    };

    return {
      selectNone,
      selectTran,
      selectDisplay,
      selectTransition,
      displayButtonTransition,
    };
  },

});
