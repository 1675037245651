






























































































































































































import { computed, defineComponent } from '@vue/composition-api';
import Store from '@/store';
import TemplateCreateWizardStatusTransitionStep from './TemplateCreateWizardStatusTransitionStep.vue';
import TemplateCreateWizardAutomatedStatusTransitionStep from './TemplateCreateWizardAutomatedStatusTransitionStep.vue';

export default defineComponent({
  emit: ['createTemplate', 'navigateBack', 'closeWizard'],

  components: {
    TemplateCreateWizardStatusTransitionStep,
    TemplateCreateWizardAutomatedStatusTransitionStep,
  },
  props: {
    tempId: {
      type: String,
      default: null,
    },
    isPublished: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const isLoading = computed(
      () => Store.getters['workOrderTemplate/getLoading'] as boolean,
    );
    const workOrderTemplateData = computed(() => Store.getters['workOrderTemplate/getData']);
    const tab = null;

    const selectedManualCreateValue = computed((): string => Store.getters['workOrderTemplate/getManualCreateValue']);

    const selectedTemplates = computed(() => Store.getters['workOrderTemplate/getAddedJobTemplates']);
    const defaultCreateStatus = computed(
      () => Store.getters['workOrderTemplate/getDefaultTemplateCreateStatus'],
    );

    const headers = [
      { text: 'Template Name', value: 'jobTemplateName' },
      { text: 'Default', value: 'isDefault' },
      { text: 'Created on', value: 'createdOn' },
    ];

    /**
     * Emits an event warning that user wants to navigate to the previous page
     * of the wizard and passes the current step as param
     */
    const navigateBack = () => {
      emit('navigateBack', 'summary');
    };

    /**
     * Emits an event warning that user wants to navigate to the previous page
     * of the wizard and passes the current step as param
     */
    const createTemplate = () => {
      emit('createTemplate');
    };

    /**
     *  @summary emits closeWizard in the parent component this will close the dialog
     *  @author Rory Harford
     */
    const closeDialog = () => {
      emit('closeWizard');
    };

    /**
     *  @summary function calls the edit Template action in the store
     *  @author Rory Harford
     */
    const saveTemplate = () => {
      emit('saveTemplate');
    };

    return {
      tab,
      isLoading,
      selectedTemplates,
      headers,
      workOrderTemplateData,
      selectedManualCreateValue,
      navigateBack,
      createTemplate,
      defaultCreateStatus,
      saveTemplate,
      closeDialog,
    };
  },
});
