


























































































































































































































































































































import {
  defineComponent, computed, ref,
} from '@vue/composition-api';
import Store from '@/store';
import { IWorkOrderTemplateStatuses } from '@/types';
import TemplateCreateWizardAutomatedStatusTransitionEditStep from './TemplateCreateWizardAutomatedStatusTransitionEditStep.vue';

export default defineComponent({
  emit: ['navigateNext', 'navigateBack'],
  components: {
    TemplateCreateWizardAutomatedStatusTransitionEditStep,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup(_props, { emit }) {
    const navStatusData = computed(
      ():
      (IWorkOrderTemplateStatuses) [] => Store.getters['workOrderTemplate/getData'].jobTemplateStatuses,
    );
    // this will be changed for the edit button currently just using it to hide button/v-card
    const hideIfEdit = ref(true);
    // this will be updated once we have access to Geopal Statuses through API
    const geopalStatuses = ref([
      {
        geopalStatusId: 0,
        geopalStatusName: 'Unassigned',
      },
      {
        geopalStatusId: 1,
        geopalStatusName: 'Assigned',
      },
      {
        geopalStatusId: 3,
        geopalStatusName: 'Rejected',
      },
      {
        geopalStatusId: 4,
        geopalStatusName: 'Completed',
      },
      {
        geopalStatusId: 5,
        geopalStatusName: 'Deleted',
      },
      {
        geopalStatusId: 6,
        geopalStatusName: 'In Progress',
      },
      {
        geopalStatusId: 7,
        geopalStatusName: 'Accepted',
      },
      {
        geopalStatusId: 8,
        geopalStatusName: 'Incomplete',
      },
      {
        geopalStatusId: 9,
        geopalStatusName: 'Review',
      },
      {
        geopalStatusId: 10,
        geopalStatusName: 'Archive',
      },

      {
        geopalStatusId: 11,
        geopalStatusName: 'Linked',
      },
      {
        geopalStatusId: 12,
        geopalStatusName: 'Cancelled',
      },
      {
        geopalStatusId: 13,
        geopalStatusName: 'Pending',
      },
      {
        geopalStatusId: 14,
        geopalStatusName: 'Updated',
      },
      {
        geopalStatusId: 15,
        geopalStatusName: 'Planned',
      },
    ]);

    const displayEdit = ref(false);
    const selectedOption = ref('Or');
    const inboundRecived = ref(true);
    const inboundRecivedCard = ref(true);
    const fieldChange = ref(true);
    const fieldChangeCard = ref(true);
    const jobStatusChange = ref(true);
    const jobStatusChangeCard = ref(true);
    const selectedItem = ref();

    /**
     * Emits an event warning that user wants to navigate to the next page
     * of the wizard and passes the current step as param
     */
    const navigateNext = () => {
      emit('navigateNext', 'automated');
    };

    /**
     * Emits an event warning that user wants to navigate to the previous page
     * of the wizard and passes the current step as param
     */
    const navigateBack = () => {
      emit('navigateBack', 'automated');
    };

    /*
    * When a user selects a status in the nav panel enable/disable buttons
    */
    const selectStatus = (item: IWorkOrderTemplateStatuses) => {
      /* reset all items */
      fieldChange.value = false;
      jobStatusChange.value = false;
      inboundRecived.value = false;
      inboundRecivedCard.value = true;
      fieldChange.value = false;
      fieldChangeCard.value = true;
      jobStatusChange.value = false;
      jobStatusChangeCard.value = true;

      item.statusInbound = {
        statusInboundName: '',
      };
      item.jobStatusChange = {
        geopalStatusId: 0,
        geopalStatusName: '',
      };

      selectedItem.value = item;
    };

    /*
    * When a user clicks the 'Inbound Recieved' button
    */
    const selectInBoundRecieved = () => {
      inboundRecivedCard.value = !inboundRecivedCard.value;
    };

    /*
    * When a user clicks the 'Field Change' button
    */
    const selectFieldChange = () => {
      fieldChangeCard.value = !fieldChangeCard.value;
    };

    /*
    * When a user clicks the 'Job Status Change' button
    */
    const selectJobStatusChange = () => {
      jobStatusChangeCard.value = !jobStatusChangeCard.value;
    };

    /*
     * When a user is typing the status inbound name it is saved in the vuex store
     */
    const updateNewStatusInbound = (value: string) => {
      selectedItem.value.statusInbound.statusInboundName = value;
    };

    /*
     * When a user is selecting the status from 'GeoPal Status' this will be saved in the vuex store
     */
    const updateJobStatusChange = (value: string) => {
      selectedItem.value.jobStatusChange = value;
    };

    const addTrigger = () => {
      displayEdit.value = true;
    };

    return {
      navStatusData,
      selectStatus,
      navigateNext,
      navigateBack,
      addTrigger,
      displayEdit,
      selectedOption,
      inboundRecived,
      inboundRecivedCard,
      fieldChange,
      fieldChangeCard,
      jobStatusChange,
      jobStatusChangeCard,
      selectInBoundRecieved,
      selectFieldChange,
      selectJobStatusChange,
      selectedItem,
      updateNewStatusInbound,
      hideIfEdit,
      geopalStatuses,
      updateJobStatusChange,
    };
  },
});
