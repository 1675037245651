







































































































































import {
  defineComponent, ref, computed,
} from '@vue/composition-api';
import Store from '@/store';
import { IJobTemplate, IWorkOrderTemplateList } from '@/types';

export default defineComponent({
  emit: ['navigateNext', 'navigateBack'],

  setup(_props, { emit }) {
    const jobTemplateSelection = ref();
    const addedTemplates = computed(() => Store.getters['workOrderTemplate/getAddedJobTemplates']);
    const defaultTemp = computed(() => Store.getters['workOrderTemplate/getDefaultJobTemplate']);

    const jobTemplateItems = computed(() => {
      const items = Store.getters['jobTemplate/getData'] as IJobTemplate[];
      if (items && items.length) {
        return items.map((item) => ({
          isDefault: false,
          autoCreate: false,
          createOnStatus: null,
          type: 'none',
          id: null,
          jobTemplateName: item.name,
          jobTemplateId: item.jobTemplateId,
        }));
      }
      return [];
    });

    const headers = [
      {
        text: 'Job Template', align: 'start', value: 'jobTemplateName', width: '45%',
      },
      { text: 'Default?', value: 'isDefault', width: '30%' },
      {
        text: 'Actions', value: 'actions', sortable: false, width: '25%',
      },
    ];

    /**
     * Emits an event warning that user wants to navigate to the previous page
     * of the wizard and passes the current step as param
     * Clears the table and the combobox fields
     */
    const navigateBack = () => {
      emit('navigateBack', 'templateSelection');
    };

    /**
     * Method is called when user deletes a template from the availabe
     */
    const deleteTemplate = (item: IWorkOrderTemplateList) => {
      Store.dispatch('workOrderTemplate/deleteTemplate', item.jobTemplateId);
    };

    /**
     * Method is called when user selects the default template
     */
    const defaultTemplate = (item: IWorkOrderTemplateList) => {
      Store.dispatch('workOrderTemplate/selectDefaultTemplate', item);
    };

    /**
     * Emits an event warning that user wants to navigate to the next page
     * of the wizard and passes the current step as param
     */
    const navigateNext = () => {
      emit('navigateNext', 'templateSelection');
    };

    const jobTemplateField = {
      label: 'Job Template',
      rules: {
        /**
         * Checks if value is not falsy
         * @param {string} value Value to be checked
         * @return {boolean | string} True if valid or a text if false
         */
        required: (template: string | number | undefined): boolean | string => !!template || 'This field is required',

        noDuplicates: (template: IWorkOrderTemplateList) => {
          if (template) {
            const tempChecker = template.jobTemplateId ? template.jobTemplateId : template;
            const itemIndex = addedTemplates.value.findIndex(
              (item: IWorkOrderTemplateList) => item.jobTemplateId === tempChecker,
            );
            return itemIndex >= 0 ? 'This job template has already been added' : true;
          }
          return true;
        },
      },
    };
    /**
     * Checks if template name field is valid
     * @return {boolean} True if valid
     */
    const validateJobTemplateSelection = computed((): boolean => {
      const jobTemplateValue = Store
        .getters['workOrderTemplate/getData'].availableJobTemplates.addingTemplate;

      const noDuplicates = jobTemplateField.rules.noDuplicates(jobTemplateValue);
      if (typeof noDuplicates === 'string') return false;

      if (!jobTemplateValue) return false;

      const required = jobTemplateField.rules.required(jobTemplateValue);
      return typeof required === 'boolean' && required;
    });

    /**
     * when the add template combobox is updated
     * @param {string} value Field value
     */
    const onChangeJobTemplate = (template: IWorkOrderTemplateList | undefined) => {
      const value = template && template.jobTemplateId ? template.jobTemplateId : '';
      Store.dispatch('workOrderTemplate/setAddJobTemplate', value);
    };

    /**
     * when the user adds a template (i.e click add button)
     */
    const addJobTemplate = () => {
      Store.dispatch('workOrderTemplate/setSelectedJobTemplates', jobTemplateSelection.value);
      Store.dispatch('workOrderTemplate/setAddJobTemplate', '');
      jobTemplateSelection.value = '';
    };

    return {
      validateJobTemplateSelection,
      jobTemplateField,
      jobTemplateItems,
      navigateNext,
      navigateBack,
      jobTemplateSelection,
      onChangeJobTemplate,
      addedTemplates,
      headers,
      addJobTemplate,
      defaultTemplate,
      deleteTemplate,
      defaultTemp,
    };
  },
});
