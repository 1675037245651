













import {
  defineComponent, ref, computed,
} from '@vue/composition-api';
import TemplateCreateWizardDialog from '@/components/TemplateCreateWizard/TemplateCreateWizardDialog.vue';
import { IWorkorderTemplateData } from '@/types';
import Store from '@/store';
import router from '@/router';
import TemplateCreateWizardProgressBar from '../components/TemplateCreateWizard/TemplateCreateWizardProgressBar.vue';

export default defineComponent({
  components: {
    TemplateCreateWizardDialog,
    TemplateCreateWizardProgressBar,
  },

  setup() {
    /* have to reload data incase the page is refreshed */
    Store.dispatch('templateCustomisation/setLoading', true);
    Store.dispatch('templateCustomisation/setTemplateId', router.app.$route.params.templateId);
    Store.dispatch('templateCustomisation/loadData');

    const isLoading = computed(() => Store.getters['templateCustomisation/getLoading']);

    const templateData = ref(
      Store.getters['templateCustomisation/getData'] as IWorkorderTemplateData,
    );

    const loadTemplateStatuses = async () => {
      await Store.dispatch('workOrderTemplate/loadWorkOrderJobTemplateData', templateData.value);
    };
    loadTemplateStatuses();

    /**
     * On templateCreated emmited reloads TemplateListstore data
     */
    const onTemplateSaved = () => {
      router.go(-1);
      Store.dispatch('templateList/getUnpublishedTemplatesData');
      Store.dispatch('templateList/filter');
    };

    return {
      templateData,
      isLoading,
      onTemplateSaved,
    };
  },
});
